import React, { useState } from 'react'
import './ContactUs.scss'
import contactUsImg from '../../images/contact_us_img.webp'
import whatsappIcon from '../../images/whatsapp_icon.png'
import loader from '../../images/loader.svg'
import notify from '../../components/CustomUseNotify'
import config from '../../config.json'
// import CallIcon from '@mui/icons-material/Call';
import { LazyLoadImage } from "react-lazy-load-image-component";

const ContactUs = ({contactUsRef, contactUsFormRef}) => {
    const [userDetails, setUserDetails] = useState({ fullName: '', mobile: '', email: '', message: '' })
    const [loading, setLoading] = useState(false)

    const contactUsHandler = () => {
        const emailRegex = /\S+@\S+\.\S+/;
        if (userDetails.fullName === '' || userDetails.fullName == null) {
            notify("Full name is required.", 'warning')
            return
        };
        if (!new RegExp("^[0-9]{10}$").test(userDetails.mobile)) {
            notify('Invalid mobile number.', 'warning')
            return;
        }
        if (!emailRegex.test(userDetails.emailRegex)) {
            notify('Invalid email id.', 'warning')
            return;
        }

        fetch(`${config.HOST_NAME}/website/contactUs`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userDetails),
        })
            .then(response => response.json())
            .then(json => {
                setLoading(false)
                setUserDetails({ fullName: '', mobile: '', email: '', message: '' })
                if (json.success) notify(json.message, 'success')
                else notify(json.message, 'warning')
            })
            .catch(error => {
                setLoading(false)
                notify('Something went wrong', 'warning')
            });
    }
    return (
        <div ref={contactUsRef} className='contact_us' id='#contact_us'>


            <div className='contact_us_svg' >
                <div className='contact_us_svg_container'>
                    <div>
                        {/* <img className='contact_us_img' src={contactUsImg} alt='Contact Us' /> */}
                        <LazyLoadImage className='contact_us_img' src={contactUsImg} alt='Contact Us' />
                    </div>
                    <div className='contact_us_svg_description'>
                        <p>We can make a special offer for you.</p>
                        <p>Just fill up the form and our customer delight team will contact you or send us whatsapp message.</p>
                    </div>

                    <div className="contact_us_cta" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'stretch', gap: 25, marginTop: '25px' }}>
                        {/*<a href="tel:+919971742678" className='cta_btn' style={{ color: "#fff", textDecoration: 'none', backgroundColor: '#2C3041' }} target="_blank" rel="noreferrer">
                            <p>Call</p>
                            <CallIcon style={{ fontSize: 22, marginLeft: 10 }} />
                    </a>*/}
                        <a href=' https://wa.me/919971742678' className='cta_btn' style={{ color: "#18cc69", textDecoration: 'none', backgroundColor: '#fff' }} target="_blank" rel="noreferrer">
                            <p>Whatsapp</p>
                            <LazyLoadImage src={whatsappIcon} alt='whatsapp' style={{ width: 25, marginLeft: '10px' }} />
                        </a>
                    </div>
                </div>
            </div>


            <div ref={contactUsFormRef} className='contact_us_form' id='#contact_us_form' >

                <div className='contact_us_form_container'>
                    <p className='p1'>Contact Us</p>
                    <p className='p2'>Please share your details we will call you back</p>

                    <div style={{ marginTop: 5 }}>
                        <input className='customInputFilter'
                            type="text"
                            value={userDetails.fullName}
                            onChange={(e) => setUserDetails((prev) => {
                                return { ...prev, fullName: e.target.value }
                            })}
                            placeholder='Full Name'
                        />
                        <input className='customInputFilter'
                            type="text"
                            value={userDetails.mobile}
                            onChange={(e) => setUserDetails((prev) => {
                                return { ...prev, mobile: e.target.value }
                            })}
                            placeholder='Mobile No.'
                        />
                        <input className='customInputFilter'
                            type="text"
                            value={userDetails.email}
                            onChange={(e) => setUserDetails((prev) => {
                                return { ...prev, email: e.target.value }
                            })}
                            placeholder='Email ID'
                        />
                        <textarea className='customInputFilter'
                            type="text"
                            rows="6"
                            value={userDetails.message}
                            onChange={(e) => setUserDetails((prev) => {
                                return { ...prev, message: e.target.value }
                            })}
                            placeholder='Message'
                        />


                        <div className="contact_form_cta_container">
                            <div className='send_btn' onClick={contactUsHandler}>
                                <p>SEND</p>
                                {loading && <img src={loader} alt='loader' style={{ width: '20px', marginLeft: '5px' }} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ContactUs