import React from 'react'
import './App.scss';

import Routes from './routes/Routes';
import TemplateProvider from './customTheme/themeProvider';
import GlobalStateProvider from './stateProvider/StateProvider';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

toast.configure({
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
})

function App() {

  return (
    <div className="App">
      <TemplateProvider>
        <GlobalStateProvider>
          <Routes />
        </GlobalStateProvider>
      </TemplateProvider>
    </div>
  );
}

export default App;
