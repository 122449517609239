import React, { useState, useRef, useEffect } from 'react'
import './Business.scss'
import smart_city_img from '../../images/smart_city_img.webp'
import YoutubeEmbed from '../../components/YoutubeEmbed'
import LinearProgress from '@mui/material/LinearProgress';

import corporate_icon from '../../images/corporate_icon.svg'
import mall_icon from '../../images/mall_icon.svg'
import society_icon from '../../images/society_icon.svg'
import parking_owner_icon from '../../images/parking_owner_icon.svg'
import smart_city_icon from '../../images/smart_city_icon.svg'

import corporate_icon_white from '../../images/corporate_icon_white.svg'
import mall_icon_white from '../../images/mall_icon_white.svg'
import society_icon_white from '../../images/society_icon_white.svg'
import parking_owner_icon_white from '../../images/parking_owner_icon_white.svg'
import smart_city_icon_white from '../../images/smart_city_icon_white.svg'

import club125 from '../../images/club125.jpg'
import aiims from '../../images/aiims.jpg'
import ats from '../../images/ats.jpeg'
import sector18 from '../../images/sector18.jpg'

import { LazyLoadImage } from "react-lazy-load-image-component";


const parkSmartBusiness = [
    { name: 'Corporates', image: club125, type: "video", icon_green: corporate_icon, icon_white: corporate_icon_white },
    { name: 'Malls/Hospitals', image: aiims, type: "image", icon_green: mall_icon, icon_white: mall_icon_white },
    { name: 'Societies', image: ats, type: "image", icon_green: society_icon, icon_white: society_icon_white },
    { name: 'Parking Owners', image: sector18, type: "image", icon_green: parking_owner_icon, icon_white: parking_owner_icon_white },
    { name: 'Smart Cities', image: smart_city_img, type: "image", icon_green: smart_city_icon, icon_white: smart_city_icon_white },
]

const Business = () => {
    const [selectedBusinessIndex, setSelectedBusinessIndex] = useState(0);
    const [progress, setProgress] = React.useState(0);
    const slideshowTimeout = useRef(null);

    useEffect(() => {
        startTimer();
        return () => {
          // Clear the timer when the component unmounts
          clearTimeout(slideshowTimeout.current);
        };
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [selectedBusinessIndex]);
    
      const startTimer = () => {
        if (slideshowTimeout.current) clearTimeout(slideshowTimeout.current);
    
        let waitTime;
        if (selectedBusinessIndex === 0) waitTime = 60000;
        else waitTime = 40000;
    
        let step = 100 / (waitTime / 1000); // Calculate step size based on wait time
        let currentProgress = 0;
    
        slideshowTimeout.current = setInterval(() => {
          currentProgress += step;
          if (currentProgress >= 100) {
            // Time is over, change the selectedBusinessIndex
            setSelectedBusinessIndex((prevIndex) =>
              prevIndex < parkSmartBusiness.length - 1 ? prevIndex + 1 : 0
            );
            currentProgress = 0; // Reset progress
          }
          setProgress(currentProgress);
        }, 1000);
      };
    

    return (
        <div className='business_section'>
            <div className='business_container'>
                <p>Business Solutions for <span>{parkSmartBusiness[selectedBusinessIndex].name}</span></p>
                {/* <a href='https://parksmart.co.in/business/#/login' target="_blank" rel="noreferrer" className='dashboard_link' >LOGIN</a> */}
            </div>

            <div className='business_item_container'>
                <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    {parkSmartBusiness.map((business, index) => (
                        <>
                        <div key={business.name} className='business_item' style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', backgroundColor: parkSmartBusiness[selectedBusinessIndex].name === business.name ? '#18cc69' : '#fff', height: '60px', boxShadow: '0px 4px 5px -3px rgba(0, 0, 0, 0.25)', marginTop: '2px', cursor: 'pointer' }} onClick={() => setSelectedBusinessIndex(index)}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '60px', height: '60px', borderRadius: '60px', backgroundColor: parkSmartBusiness[selectedBusinessIndex].name === business.name ? '#F8F8F8' : '#18cc69' }}>
                                <LazyLoadImage src={parkSmartBusiness[selectedBusinessIndex].name === business.name ? business.icon_green : business.icon_white} style={{ width: 30, height: 30, color: 'red' }} alt='icon' />
                            </div>
                            <p style={{ color: "#272840", fontWeight: '700', fontSize: '18px', marginLeft: 50 }}>{business.name}</p>
                            
                        </div>
                        {selectedBusinessIndex === index && (
                            <div className='linear-progress-container'>
                            <LinearProgress variant="determinate" color="primary" className='progress-bar' value={progress}/>
                            </div>
                        )}
                        </>
                    ))}
                </div>

                <div className='business_images'  ref={slideshowTimeout}>
                    {parkSmartBusiness[selectedBusinessIndex].type === 'image' ?
                        <LazyLoadImage src={parkSmartBusiness[selectedBusinessIndex].image} alt='Images' style={{borderRadius: 20}} />
                        :
                        <YoutubeEmbed embedId="loJgTJEKv2U" />
                    }
                </div>
            </div>
        </div>
    )
}

export default Business