import React, { useEffect, useRef, useState } from "react";
import "../App";

const YoutubeEmbed = ({ embedId }) => {
  const [isInViewport, setIsInViewport] = useState(false);
  const iframeRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null, // use the viewport as the root
      rootMargin: "0px", // no additional margins
      threshold: 0.5, // 50% of the component must be in the viewport
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        setIsInViewport(entry.isIntersecting);
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (iframeRef.current) {
      observer.observe(iframeRef.current);
    }

    return () => {
      if (iframeRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(iframeRef.current);
      }
    };
  }, []);
  

  return (
    <div className="video-responsive">
      <iframe
        src={`https://www.youtube.com/embed/${embedId}?${isInViewport ? "autoplay=1&mute=1" : "autoplay=0&mute=1"}`}
        title="Embedded youtube"
        frameBorder="0"
        allow="autoplay"
        allowFullScreen
        ref={iframeRef}
      ></iframe>
    </div>
  );
};

export default YoutubeEmbed;

