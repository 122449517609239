import React from 'react'
import './DashboardLogin.scss'
import Button from '@mui/material/Button';
import DashboardImage from '../../images/dashboard.png'

const DashboardLogin = () => {
    return (
        <div className='dashboard_login_section'>
            <div className='dashboard_container'>
                <div>
                    <p className='title'>Business Dashboard</p>

                    <div className='dashboadImgMV'>
                        <img src={DashboardImage} alt='dashboardImage' style={{width: '100%', height:"100%"}} />
                    </div>
                    <div className='about'>
                        <p>Welcome to the <span style={{color:"#18CC69", fontWeight:"bold"}}>ParkSmart</span> Dashboard! Log in now to access comprehensive parking analytics, hardware status, parking logs, and financial insights. Take control of your parking operations and optimize efficiency with our user-friendly interface.</p>
                        <p>Log in to our Dashboard today and experience how it can transform the way you manage your parking spaces.</p>
                    </div>
                    <div className="dashboard_link_container">
                        <a href='https://parksmart.co.in/business/#/login' target="_blank" rel="noreferrer" className='dashboard_link' >
                            <Button color="primary" variant="contained" style={{ textTransform: "none", fontSize: 16, letterSpacing: "0.06em", height: "60px", minWidth: "120px", backgroundColor: "#18CC69", color: "#FFF", fontFamily: 'Poppins' }} disableElevation>
                            Business Login
                            </Button>
                        </a>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#18CC69', minWidth: 340 }}>

            </div>
            <img className='dashboadImg' src={DashboardImage} alt='dashboardImage' />
            {/* <div className='dashboadImgMV' style={{ justifyContent: 'center', alignItems: 'center', backgroundColor: '#18CC69' }}>
                <img src={DashboardImage} alt='dashboardImage' />
            </div> */}
        </div>
    )
}

export default DashboardLogin