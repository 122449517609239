import React from 'react'
import footerBg from '../../images/footer_bg_img.png'
import logo from '../../images/logo_white.png'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import PlaceIcon from '@mui/icons-material/Place';
import './Footer.scss'
import playStore from '../../images/playStore.png';
import appStore from '../../images/appStore.png';
import config from '../../config.json';
import qrCode from '../../images/qrcode.png'
import useWindowDimensions from '../../components/GetWindowDimensions';
import { LazyLoadImage } from "react-lazy-load-image-component";

const Footer = () => {
    const { width } = useWindowDimensions();

    return (
        <>
            <div className='footer_section'>
                <div className='footer_background' style={{ position: "absolute", bottom: 0, width: "100%", backgroundImage: `url(${footerBg})`, backgroundSize: "contain", backgroundRepeat: "repeat-x" }} />
                <div className='footer_container'>
                    <div className='div_1'>
                        <LazyLoadImage src={logo} alt='logo' style={{ width: 200 }} />

                        <div className='follow_us'>
                            <p>Follow us:</p>
                            <div>
                                <a href='https://www.linkedin.com/company/parksmarthq/' target="_blank" rel="noreferrer" aria-label="LinkedIn">
                                    <LinkedInIcon style={{ fontSize: 28 }} />
                                </a>
                                <a href='https://www.instagram.com/parksmarthq/' target="_blank" rel="noreferrer" aria-label="Instagram">
                                    <InstagramIcon style={{ fontSize: 28 }} />
                                </a>
                                <a href='https://www.facebook.com/ParkSmartHQ' target="_blank" rel="noreferrer" aria-label="Facebook">
                                    <FacebookIcon style={{ fontSize: 28 }} />
                                </a>
                                <a href='https://twitter.com/parksmarthq/' target="_blank" rel="noreferrer" aria-label="Twitter">
                                    <TwitterIcon style={{ fontSize: 28 }} />
                                </a>
                                <a href='https://www.youtube.com/@parksmarthq' target="_blank" rel="noreferrer" aria-label="Youtube">
                                    <YouTubeIcon style={{ fontSize: 32 }} />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className='div_2'>
                        <p>Contact Us</p>

                        <a className='info_container' href="mailto:info@parksmart.in" target="_blank" rel="noreferrer" aria-label="Email">
                            <EmailIcon />
                            <p>info@parksmart.in</p>
                        </a>

                        <p style={{ marginTop: 30, fontWeight: 600 }}>For business enquires:</p>

                        <a className='info_container' href="mailto:business@parksmart.in" target="_blank" rel="noreferrer" aria-label="Email">
                            <EmailIcon />
                            <p>business@parksmart.in</p>
                        </a>

                        <a className='info_container' href="https://goo.gl/maps/eqFCMPPgpbf4LorQ6" target="_blank" rel="noreferrer" aria-label="Address">
                            <PlaceIcon />
                            <p>BR-26, Sector 116, Noida, Uttar Pradesh 201301</p>
                        </a>

                    </div>

                    <div className='div_2'>
                        <p>Download ParkSmart App</p>
                        <div className='share' style={{ marginTop: 12 }}>
                            <a href='https://play.google.com/store/apps/details?id=in.parksmart.user' target="_blank" rel="noreferrer" aria-label="Play Store">
                                <LazyLoadImage src={playStore} alt='Play Store' />
                            </a>
                            <a href='https://apps.apple.com/in/app/parksmart-your-parking-pass/id1538546356' target="_blank" rel="noreferrer" aria-label="App Store">
                                <LazyLoadImage src={appStore} alt='App Store' />
                            </a>
                        </div>

                        {width > 450 && <>
                            <p style={{ marginTop: 30, fontWeight: 600 }}>Scan QR to download</p>
                            <div
                                style={{ backgroundColor: "#fff", borderRadius: 10, width: 132, height: 130, display: "flex", justifyContent: "center", alignItems: "center", marginTop: 15 }}
                            >
                                <LazyLoadImage src={qrCode} style={{ width: 115 }} alt='QR Code' />
                            </div>
                        </>}
                    </div>
                </div>
            </div>

            <div className='second_footer'>
                <p>© 2020 ParkSmart. All rights reserved</p>
                <p style={{ fontWeight: 400 }}><a href={`${config.HOST_NAME}/parksmart-app/terms-and-conditions/`} target="_blank" rel="noreferrer">Terms & Conditions</a> | <a href={`${config.HOST_NAME}/parksmart-app/privacy-policy/`} target="_blank" rel="noreferrer" aria-label="Terms and Conditions">Privacy Policy</a>{/*  | Site Map */}</p>
            </div>
        </>
    )
}

export default Footer