import React, { useState, useContext } from 'react'
import { useNavigate } from "react-router-dom"
import './BuyFastagScreen.scss'
import buyFastagImg from '../../images/buy_fastag_img.png'
// import loader from '../../images/loader.svg'
import logo from '../../images/logo_white_black.png'
import config from '../../config.json'
import LogoutIcon from '@mui/icons-material/Logout';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import { ContextProvider } from '../../stateProvider/StateProvider'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import DialogTitle from '@mui/material/DialogTitle';
import Portal from '@mui/material/Portal';

import { makeStyles } from '@mui/styles';
import notify from '../../components/CustomUseNotify'
import { Helmet } from "react-helmet";

const FASTAG_CHARGE = 250

const useStyles = makeStyles({
    list: {
        marginBottom: 0,
    },
    parkingSelectorContainer: {
        display: "grid",
        justifyContent: "space-between",
    },
    dialogPaper: {
        borderRadius: 15,
        padding: "5px 15px 15px",
        maxWidth: 350
    },
    dialogButton: {
        borderRadius: 100,
        padding: "8px 25px",
        fontFamily: 'Poppins'
    },
    dialogPaperBig: {
        borderRadius: 15,
        padding: "5px 5px 10px",
    },
    input: {
        width: "500px"
    },
    MuiInputBase: {
        root: {
            width: "800px"
        }
    },
});

function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

const BuyFastagScreen = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { userData } = useContext(ContextProvider)

    const [userDetails, setUserDetails] = useState({ full_name: userData && userData.data && userData.data.name ? userData.data.name : '', address_line_1: '', address_line_2: '', pincode: '', city: '', state: '' })
    const [tagsQuantity, setTagsQuantity] = useState(1)
    const [loading, setLoading] = useState(false)

    const [paymentFailedDialog, setPaymentFailedDialog] = useState(null);

    const incTagsQuantityHandler = () => {
        setTagsQuantity(prev => prev + 1)
    }

    const decTagsQuantityHandler = () => {
        if (tagsQuantity > 1) setTagsQuantity(prev => prev - 1)
    }

    const logoutHandler = () => {
        localStorage.clear();
        navigate('/login')
    }

    const closePaymentFailedDialog = () => {
        setPaymentFailedDialog(null);
    };

    const onSubmit = () => {

        const params = {
            ...userDetails,
            amount: FASTAG_CHARGE * tagsQuantity,
            tags_quantity: tagsQuantity
        };

        if (params.full_name == null || params.full_name === "") {
            notify("Full name is required", "warning")
            return
        }
        if (params.address_line_1 == null || params.address_line_1 === "") {
            notify("Address is required", "warning")
            return
        }

        if (params.pincode == null || params.pincode === "") {
            notify("Pincode is required", "warning")
            return
        }

        if (params.city == null || params.city === "") {
            notify("City is required", "warning")
            return
        }

        if (params.state == null || params.state === "") {
            notify("State is required", "warning")
            return
        }

        if (params.tags_quantity == null || params.tags_quantity === "") {
            notify("Invalid Tags Quantity", "warning")
            return
        }

        if (params.amount == null || params.amount === "") {
            notify("Amount is required", "warning")
            return
        }
        if (isNaN(params.amount) || parseFloat(params.amount) <= 0) {
            notify("Invalid Amount", "warning")
            return;
        }

        setLoading(true);
        closePaymentFailedDialog();
        fetch(`${config.HOST_NAME}/commuter/users/initiatePurchaseTagsPayment`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(json => {
                if (json.success) {
                    const order = json.data.order;
                    var options = {
                        description: 'Purchased Tags Charges',
                        currency: order.currency,
                        key: config.RAZOR_PAY_KEY_ID,
                        amount: order.amount,
                        name: 'ParkSmart',
                        order_id: order.id,
                        notes: {
                            user_name: userDetails.full_name,
                            purpose: 'Purchase FASTags'
                        },
                        prefill: {
                            name: params.name,
                        },
                        theme: {
                            color: '#18cc69'
                        },
                        modal: {
                            ondismiss: () => {
                                console.log("dismiss")
                                setLoading(false);
                                const description = "Payment is cancelled by user";
                                setPaymentFailedDialog(description);
                                updatePurchasedTagStatus(json.data.transaction_id, "CANCELLED", description);
                            }
                        },
                        handler: checkoutData => {
                            fetch(`${config.HOST_NAME}/commuter/users/verifyPurchaseTagsPayment`, {
                                method: 'POST',
                                headers: {
                                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({ ...checkoutData, transaction_id: json.data.transaction_id, ...params }),
                            })
                                .then(response => response.json())
                                .then(json => {
                                    setLoading(false);
                                    if (json.success) {
                                        resetFormState();
                                        notify("Payment Successful", "success");
                                    } else {
                                        setPaymentFailedDialog(json.message);
                                    }
                                })
                                .catch((error) => {
                                    setLoading(false);
                                    setPaymentFailedDialog("Could not verify Payment");
                                });
                        },
                    }
                    const razorpay = new window.Razorpay(options);
                    razorpay.on('payment.failed', function ({ error }) {
                        setLoading(false);
                        let description, status;
                        if (isJsonString(error.description)) {
                            const errorObj = JSON.parse(error.description);
                            if (errorObj.error) {
                                description = errorObj.error.description;
                                status = errorObj.error.reason;
                            } else {
                                description = errorObj.description;
                                status = errorObj.reason;
                            }
                        } else {
                            description = error.description;
                        }
                        setPaymentFailedDialog(description);
                        updatePurchasedTagStatus(json.data.transaction_id, status === "payment_cancelled" ? "CANCELLED" : "FAILED", description);
                    });

                    razorpay.open();
                } else {
                    setLoading(false);
                    notify(json.message, "warning");
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                notify("Check your internet connection", "warning");
            });

    };

    const resetFormState = () => {
        setTagsQuantity(1)
        setUserDetails({ full_name: userData && userData.data && userData.data.name ? userData.data.name : '', address_line_1: '', address_line_2: '', pincode: '', city: '', state: '' })
    }


    const updatePurchasedTagStatus = async (transactionId, paymentStatus, remarks) => {
        fetch(`${config.HOST_NAME}/commuter/users/payment/${transactionId}/status`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ payment_status: paymentStatus, remarks: remarks })
        });
    };



    return (
        <>
            <Helmet>
                <script src="https://checkout.razorpay.com/v1/checkout.js"></script>
            </Helmet>

            <div className='buy_fastag'>

                <div className='buy_fastag_container'>
                    <div className='about_fastag' >
                        <div style={{ width: "100%" }}>
                            <div>
                                <img src={buyFastagImg} alt='Buy Fastag' style={{ width: "100%" }} />
                            </div>
                            <div className='about_fastag_description'>
                                <p>One FASTag for all your needs.</p>
                                <p>Just fill up the form and our customer delight team will contact you or send us whatsapp message</p>
                            </div>

                        </div>
                    </div>


                    <div className='buy_fastag_form' >
                        <div className='buy_fastag_form_container'>
                            <p className='p1'>Buy FASTag</p>
                            <p className='p2'>Please share your details we will call you back</p>

                            <div>
                                <input className='customInputFilter'
                                    type="text"
                                    value={userDetails.full_name}
                                    onChange={(e) => setUserDetails((prev) => {
                                        return { ...prev, full_name: e.target.value }
                                    })}
                                    placeholder='Full Name'
                                />
                                <input className='customInputFilter'
                                    type="text"
                                    value={userDetails.address_line_1}
                                    onChange={(e) => setUserDetails((prev) => {
                                        return { ...prev, address_line_1: e.target.value }
                                    })}
                                    placeholder='Address Line 1'
                                />
                                <input className='customInputFilter'
                                    type="text"
                                    value={userDetails.address_line_2}
                                    onChange={(e) => setUserDetails((prev) => {
                                        return { ...prev, address_line_2: e.target.value }
                                    })}
                                    placeholder='Address Line 2'
                                />
                                <input className='customInputFilter'
                                    type="text"
                                    value={userDetails.pincode}
                                    onChange={(e) => setUserDetails((prev) => {
                                        return { ...prev, pincode: e.target.value }
                                    })}
                                    placeholder='Pin Code'
                                />

                                <div className='two_input_flex_container'>
                                    <input className='customInputFilter'
                                        type="text"
                                        value={userDetails.city}
                                        onChange={(e) => setUserDetails((prev) => {
                                            return { ...prev, city: e.target.value }
                                        })}
                                        placeholder='City'
                                    />
                                    <input className='customInputFilter'
                                        type="text"
                                        value={userDetails.state}
                                        onChange={(e) => setUserDetails((prev) => {
                                            return { ...prev, state: e.target.value }
                                        })}
                                        placeholder='State'
                                    />
                                </div>

                                <div className='payment_summary_container'>
                                    <p className='number_of_tags'>
                                        {`No. of Fastag (@₹ ${FASTAG_CHARGE})`}
                                    </p>

                                    <div className='inc_dec_tags_container'>
                                        <div className='inc_dec_btn' onClick={decTagsQuantityHandler}>
                                            <p>-</p>
                                        </div>

                                        <p className='tags_quantity'>{tagsQuantity}</p>

                                        <div className='inc_dec_btn' onClick={incTagsQuantityHandler}>
                                            <p>+</p>
                                        </div>

                                    </div>
                                </div>

                                <hr className='hr_1' />

                                <p className='number_of_tags' style={{ margin: "10px 0px 10px 0px" }}>
                                    Payment Summary
                                </p>

                                <div className='payment_summary_details'>
                                    <p>
                                        FASTag Price
                                    </p>
                                    <p>
                                        ₹ {FASTAG_CHARGE}
                                    </p>
                                </div>
                                <div className='payment_summary_details'>
                                    <p>
                                        Delivery Charges (included)
                                    </p>
                                    <p>
                                        ₹ 50
                                    </p>
                                </div>

                                {/* <div className='pay_btn_container_small_screen'>
                                    <p>₹ {FASTAG_CHARGE * tagsQuantity}</p>
                                    <div className='pay_btn' onClick={onSubmit}>
                                        <p>PAY NOW</p>
                                    </div>
                                </div> */}

                                <hr className='hr_2' />

                                <div className='payable_amount_container'>
                                    <p className='number_of_tags'>
                                        Payable Amount
                                    </p>
                                    <p className='number_of_tags'>
                                        ₹ {FASTAG_CHARGE * tagsQuantity}
                                    </p>
                                </div>
                            </div>


                        </div>


                    </div>
                </div>

                <div className='pay_btn_container'>
                    <p>₹ {FASTAG_CHARGE * tagsQuantity}</p>
                    <div className='pay_btn' onClick={onSubmit}>
                        <p>PAY NOW</p>
                        {/* {loading && <img src={loader} alt='loader' style={{ width: '20px', marginLeft: '5px' }} />} */}
                    </div>
                </div>

                <div style={{ position: 'absolute', left: '20px', top: "10px" }}>
                    <img src={logo} alt='logo' style={{ width: '80px' }} />
                </div>

                <div style={{ position: 'absolute', right: '20px', top: "10px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', gap: '10px' }}>
                        <Avatar sx={{ bgcolor: "#18cc69", color: "#fff", width: 34, height: 34 }}>
                            <PersonIcon />
                        </Avatar>
                        <IconButton onClick={logoutHandler}>
                            <LogoutIcon />
                        </IconButton>
                    </div>
                </div>

                {loading && <Portal>
                    <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100vh", backgroundColor: "#0001", zIndex: 9999, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <CircularProgress size={50} />
                    </div>
                </Portal>}

                <Portal>
                    <Dialog open={!!paymentFailedDialog} onClose={closePaymentFailedDialog} classes={{ paper: classes.dialogPaper }}>
                        <DialogTitle style={{ color: '#de575e' }}><div style={{ fontSize: 16, textAlign: "center" }}>Payment Failed!</div></DialogTitle>
                        <DialogContent style={{ fontSize: 14, marginTop: 0, marginBottom: 10 }}>Description: {paymentFailedDialog}</DialogContent>
                        <DialogActions style={{ display: "flex", justifyContent: "space-evenly" }}>
                            <Button className={classes.dialogButton} variant="outlined" color="secondary" onClick={closePaymentFailedDialog}>Cancel</Button>
                            <Button disableElevation className={classes.dialogButton} variant="contained" color="primary" onClick={onSubmit}>Retry</Button>
                        </DialogActions>
                    </Dialog>
                </Portal>

            </div>
        </>
    )
}

export default BuyFastagScreen