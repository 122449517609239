import React, { useState, useEffect, useRef } from 'react'
import notify from '../../components/CustomUseNotify'
import './Hero.scss'
import config from '../../config.json'
import { motion, useViewportScroll, useTransform } from "framer-motion"
import AnimatedNumberCounter from '../../components/AnimatedNumberCounter'
import CallIcon from '@mui/icons-material/Call';
import useWindowDimensions from '../../components/GetWindowDimensions'

import playStore from '../../images/playStore.png'
import appStore from '../../images/appStore.png'
import car from '../../images/carV2.png';
import tyre from '../../images/tyre.png';
import logo_white_black from '../../images/logo_white_black.svg'
import logo_black from '../../images/logo_black.svg'
// import map from '../../images/map.svg'
import loader from '../../images/loader.svg'
import parking_screen from '../../images/parking_screen.webp'
import app_screen_img from '../../images/app_screen_img.webp'
import desktopScreen from "../../images/psdesktop.png";
import ScrollDownAnimation from '../../components/ScrollDownAnimation';
import qrCode from '../../images/qrcode.png'

import { LazyLoadImage } from "react-lazy-load-image-component";

const Hero = ({isContactRef}) => {
    const { width } = useWindowDimensions();
    const { scrollY } = useViewportScroll()

    const backgroundColor = useTransform(scrollY, [50, 250], ['#18cc69', '#fff']);
    const logoWhiteOpacity = useTransform(scrollY, [50, 250], [1, 0])
    const logoBlackOpacity = useTransform(scrollY, [50, 250], [0, 1])
    const logoScale = useTransform(scrollY, [150, 1000], [width > 1200 ? '900px' : width > 1025 ? '800px' : width > 900 ? '700px' : width > 650 ? '500px' : '350px', '100px']);
    const carMovement = useTransform(scrollY, [0, 1000], [width, width > 450 ? -1080 : -1300]);
    const tyreMovement = useTransform(scrollY, [0, 1000], [0, -900]);
    const carOpacity = useTransform(scrollY, [1450, 1700], [1, 0])
    // const mapScale = useTransform(scrollY, [150, 1000], ['100px', '900px']);
    // const mapTop = useTransform(scrollY, [0, 1000], ['500px', '-400px']);
    // const mapRight = useTransform(scrollY, [500, 1000], ['300px', '-250px']);
    // const mapOpacity = useTransform(scrollY, [250, 300], [0, 1])

    // const HeroSectionOpacity = useTransform(scrollY, [800, 1200], [0, 1])
    // const HeroSectionMovement = useTransform(scrollY, [500, 1000], [width, 0])
    const animationSectionOpacity = useTransform(scrollY, [999, 1000], [1, 0])
    const mobLogoScale = useTransform(scrollY, [150, 1000], ['250px', '110px']);
    const mobLogoTop = useTransform(scrollY, [150, 1000], ['40%', '0%']);
    const mobLogoLeft = useTransform(scrollY, [150, 1000], ['15%', '5%']);

    const [mobile, setMobile] = useState('')
    const [loading, setLoading] = useState(false)
    const [scrollerPOsition, setScrollerPosition] = useState(null)
    const [isExecuted, setIsExecuted] = useState(false)
    const [timerSeconds, setTimerSeconds] = useState(120); // Initial timer duration in seconds
    const [timerActive, setTimerActive] = useState(false);

    // Define a function to start the timer
    const startTimer = () => {
        setTimerActive(true);
    };

    useEffect(() => {
        let interval;
    
        if (timerActive) {
          interval = setInterval(() => {
            setTimerSeconds((prevSeconds) => {
              if (prevSeconds === 0) {
                setTimerActive(false);
                return 0;
              }
              return prevSeconds - 1;
            });
          }, 1000);
        }
    
        return () => clearInterval(interval);
      }, [timerActive]);

      useEffect(() => {
        if (timerSeconds === 0) {
          setTimerActive(false);
        }
      }, [timerSeconds]);

    scrollY.onChange((y) => {
        setScrollerPosition(y)
    })

    const sendLinkHandler = () => {
        setTimerSeconds(120);
        if (!new RegExp('^[0-9]{10}$').test(mobile)) {
          notify('Invalid mobile number', 'warning');
          return;
        }
    
        setLoading(true);
        startTimer();
    
        fetch(`${config.HOST_NAME}/website/sendLink`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ mobile_number: mobile }),
        })
          .then((response) => response.json())
          .then((json) => {
            setLoading(false);
            setMobile("");
            if (json.success) notify(json.message, 'success');
            else notify(json.message, 'warning');
          })
          .catch((error) => {
            notify('Something went wrong', 'warning');
            setLoading(false);
          });
      };
    const scrollRef = useRef(null)

    const executeScroll = () => window.scrollTo({ top: window.innerHeight*2, behavior: 'smooth' })

    useEffect(() => {
        if(!isContactRef){
            if (scrollerPOsition > 700 && !isExecuted) {
                executeScroll()
                setIsExecuted(true)
            }
            if (scrollerPOsition < 700 && isExecuted) {
                setIsExecuted(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollerPOsition])

    return (
        <div>
            <motion.div className='inti_animation_container' layoutScroll style={{ backgroundColor: backgroundColor, height: '200vh', opacity: animationSectionOpacity }}>

                {
                    width > 500 && <>
                        <motion.img src={logo_white_black} alt='logo' style={{ width: logoScale, position: 'fixed', opacity: logoWhiteOpacity }} />
                        <motion.img src={logo_black} alt='logo' style={{ width: logoScale, position: 'fixed', opacity: logoBlackOpacity }} />
                    </>
                }

                <ScrollDownAnimation />

                {
                    width < 450 && <motion.div style={{ position: 'absolute', top: mobLogoTop, left: mobLogoLeft }}>
                        <motion.img src={logo_white_black} alt='logo' style={{ width: mobLogoScale, position: 'fixed', opacity: logoWhiteOpacity }} />
                        <motion.img src={logo_black} alt='logo' style={{ width: mobLogoScale, position: 'fixed', opacity: logoBlackOpacity }} />
                    </motion.div>
                }

            </motion.div>


            <motion.div className="hero_section" id='hero' style={{ paddingTop: 80 }} ref={scrollRef}>
                <motion.div className='hero_section_main'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.9 }}
                >
                    <div className='app_info_frame_container'>
                        <div className='app_info_container'>
                            <div className='web_title_container'>
                                <p>Park<span>Smart</span> App</p>
                                <p>For all your car needs</p>
                            </div>

                            {
                                width < 450 &&
                                <>
                                    <div className='app_frame_container'>
                                        <div className='dots'>
                                            {/* <LazyLoadImage src={map} alt='Dots' /> */}
                                        </div>
                                        <div className='iphone_frame'>
                                            <LazyLoadImage src={parking_screen} alt='Iphone Frame' />
                                        </div>
                                        <div className='app_screen_container'>
                                            <LazyLoadImage src={app_screen_img} alt='Splash Screen' />
                                        </div>
                                        <div className='desktop_screen_container'>
                                            <LazyLoadImage src={desktopScreen} alt='Desktop Screen' />
                                        </div>
                                    </div>

                                    <div className='animated_counter_container'>
                                        <AnimatedNumberCounter from={0} to={100000} label="Users" />
                                        <AnimatedNumberCounter from={0} to={1000} label="Parkings" />
                                        <AnimatedNumberCounter from={0} to={2000} label="Clients" />
                                        {/* <AnimatedNumberCounter from={0} to={40000} label="5 Stars" /> */}
                                    </div>
                                </>
                            }

                            <div className='download_now_container'>
                                <p className='p1' style={{ fontWeight: '700', color: "#000000" }}>Download Now</p>
                                <p className='p2' style={{ fontSize: 18, color: '#979797', marginTop: -10 }}>{width > 450 ? "Scan the QR code or text yourself a link to download" : "Text yourself a link to download"}</p>

                                <div className='share_qr_container'>
                                    <div className='share_app_container'>

                                        <div className='send_link_container'>
                                            <CallIcon style={{ fontSize: 30, color: "#828282", marginLeft: 15 }} />
                                            <input className='customInput'
                                                type="text"
                                                value={mobile}
                                                onChange={(e) => setMobile(e.target.value)}
                                                placeholder='Mobile Number'
                                                maxLength={10}
                                            />
                                            {width > 450 && <button className='send_link_btn' onClick={sendLinkHandler} disabled={timerActive} style={{border:"none", cursor: timerActive ? "not-allowed" : "pointer"}}>
                                                <p>SEND LINK</p>
                                                {loading && <LazyLoadImage src={loader} alt='loader' style={{ width: '20px', marginLeft: '5px' }} />}
                                            </button>
                                        }
                                        </div>
                                        {width < 450 && <button className='send_link_btn' onClick={sendLinkHandler} style={{ height: '60px', marginTop: '20px' ,border:"none",  cursor: timerActive ? "not-allowed" : "pointer"}} disabled={timerActive}>
                                            <p>SEND LINK</p>
                                            {loading && <LazyLoadImage src={loader} alt='loader' style={{ width: '20px', marginLeft: '5px' }} />}
                                        </button>}
                                        <div>{timerActive && <div className='timer'>Please wait for {timerSeconds} seconds</div>} {/* Display the timer */}</div>
                                        <div className='share' style={{ marginTop: '20px', position: "relative", top: 5 }}>
                                            <a href='https://play.google.com/store/apps/details?id=in.parksmart.user' target="_blank" rel="noreferrer" aria-label='Play Store'>
                                                <LazyLoadImage src={playStore} alt='Play Store' />
                                            </a>
                                            <a href='https://apps.apple.com/in/app/parksmart-your-parking-pass/id1538546356' target="_blank" rel="noreferrer" aria-label='App Store'>
                                                <LazyLoadImage src={appStore} alt='App Store' />
                                            </a>
                                        </div>
                                    </div>

                                    {width > 450 &&
                                        <div className='qr_code' style={{ backgroundColor: "#fff", borderRadius: 10, width: 142, height: 140, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <LazyLoadImage src={qrCode} alt='QR Code' style={{ width: 115 }} />
                                        </div>}
                                </div>
                                    
                            </div>
                        </div>

                        {width > 450 &&
                            <div className='app_frame_container'>
                                {/*<div className='dots'>
                                    <LazyLoadImage src={map} alt='Dots' />
                        </div>*/}
                                <div className='iphone_frame'>
                                    <LazyLoadImage src={parking_screen} alt='Iphone Frame' />
                                </div>
                                <div className='app_screen_container'>
                                    <LazyLoadImage src={app_screen_img} alt='App Screen' />
                                </div>
                            </div>}

                        <div className='download_container' />
                    </div>

                    {width > 450 &&
                        <div className='animated_counter_container'>
                            <AnimatedNumberCounter from={0} to={100000} label="Users" />
                            <AnimatedNumberCounter from={0} to={1000} label="Parkings" />
                            <AnimatedNumberCounter from={0} to={2000} label="Clients" />
                            {/* <AnimatedNumberCounter from={0} to={40000} label="5 Stars" /> */}
                        </div>}
                </motion.div>

                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.5 }}
                >

                    {/* <div className='frame1'>
                        <img src={frame1} alt='Frame1' />
                    </div>
                    */}
                </motion.div>

                {/* <div className='Car'>
                    <motion.img src={car} alt='Car'
                        initial={{ x: 2700 }}
                        animate={{ x: 0 }}
                        transition={{ duration: delay.normal, delay: 0.05 }}
                    />
                </div> */}
            </motion.div>


            <div className='CarNew' style={{ display: scrollerPOsition > 1500 ? 'none' : 'block' }}>
                <motion.div style={{ left: carMovement, top: 80, position: 'fixed', opacity: carOpacity }}>
                    <img src={car} alt='Car' />
                    <motion.img src={tyre} alt='Tyre' style={{ rotate: tyreMovement, position: "absolute", top: 394, left: 215, width: 205 }} />
                    <motion.img src={tyre} alt='Tyre' style={{ rotate: tyreMovement, position: "absolute", top: 394, left: 878, width: 205 }} />
                    {/* <img src={tyre} alt='Tyre' /> */}
                </motion.div>
            </div>


            {/* {scrollerPOsition < 1000 && <div className='map'>
                <motion.img src={map} alt='map' style={{ width: mapScale, top: mapTop, position: 'fixed', marginRight: mapRight, opacity: mapOpacity }}
                />
            </div>} */}
        </div>
    )
}

export default Hero