import React from 'react'
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";

import HomeScreen from '../screens/HomeScreen/HomeScreen';
import LoginScreen from '../screens/LoginScreen/LoginScreen';
import BuyFastagScreen from '../screens/BuyFastagScreen/BuyFastagScreen';
import UnsubscribeScreen from '../screens/UnsubscribeScreen/UnsubscribeScreen';
import RequiredAuth from './RequiredAuth';
import {Referral} from '../sections/Referral/Referral';

const AllRoutes = () => {
    return (
        <>
            <Router>
                <Routes>
                    <Route exact path="/" element={<HomeScreen />} />
                    <Route path="/login" element={<LoginScreen />} />
                    <Route path="/web/newsletter/unsubscribe" element={<UnsubscribeScreen />} />
                    <Route element={<RequiredAuth />}>
                        <Route path="/buyFastag" element={<BuyFastagScreen />} />
                    </Route>
                    <Route path="/sales_enquiry" element={<Referral />} />
                </Routes>
            </Router>
        </>
    )
}

export default AllRoutes