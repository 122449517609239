/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "./Referral.scss";
import Gify from "../../images/ReferralGIF.gif";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import notify from "../../components/CustomUseNotify";
import { useLocation } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import { createTheme, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom"

const customTheme = createTheme({});

const Referral = () => {
  // const isSmallDevice = useMediaQuery((theme) =>
  //   customTheme.breakpoints.down("sm")
  // );
  const [page, setPage] = useState(1);
  const [siteName, setSiteName] = useState("");
  const [siteAddress, setSiteAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [sitePersonName, setSitePersonName] = useState("");
  const [sitePersonNumber, setSitePersonNumber] = useState("");
  const [sitePersonDesignation, setSitePersonDesignation] = useState("")
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [referralId, setReferralId] = useState("");

  const resetHandler = () => {
    setPage(1);
    setSiteName("");
    setSiteAddress("");
    setPincode("");
    setCity("");
    setState("");
    setSitePersonName("");
    setSitePersonNumber("");
    setName("");
    setNumber("");
    setEmail("");
    setSitePersonDesignation("")
  };

  const handlePincodeChange = async () => {
    try {
      if (!pincode) {
        // Handle the case where the pincode is not provided.
        return;
      }

      const response = await fetch(
        `https://parksmart.co.in/website/pincodeDetails?pincode=${pincode}`
      );

      if (!response.ok) {
        // Handle the case where the fetch request was not successful.
        notify(`Fetch request failed with status ${response.status}`);
      }

      const json = await response.json();

      if (json.data && json.data.length > 0) {
        setCity(json.data[0].District);
        setState(json.data[0].State);
      } else {
        setCity("");
        setState("");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  function handlePageToOne() {
    setPage(1);
  }

  const handlePageToTwo = (event) => {
    event.preventDefault();

    // Validation checks
    if (!siteName || !siteAddress) {
      toast.success("Please fill in all required fields.", {
        type: "warning",
        theme: "colored",
        style: {
          zIndex: 1301,
        },
        zIndex: 1301,
      });
      return;
    }

    if (pincode.length !== 6) {
      toast.success("Pincode must be of 6 digits.", {
        type: "warning",
        theme: "colored",
      });
      return;
    }

    if (!city || !state) {
      toast.success("Please enter a valid pincode.", {
        type: "warning",
        theme: "colored",
      });
      return;
    }
    try {
      fetch(`https://parksmart.in/website/referParkingSite`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          site_name: siteName,
          site_address: siteAddress,
          site_pincode: pincode,
          site_city: city,
          site_state: state,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            notify("Network Error: Please check your internet connection and try again");
          }
          return response.json();
        })
        .then((json) => {
          // console.log(json.referralId);
          // Proceed to the next page if validations pass
          if (!json.success) {
            toast.success(json.message, {
              type: "warning",
              theme: "colored",
            });
            return;
          }
          setReferralId(json.referralId);
          setPage(2);
        })
        .catch((error) => {
          notify("Network Error: Please check your internet connection and try again", "warning");
        });
    } catch (error) {
      notify("An error occurred while processing your request", "error");
    }
  };
  function handleApply(event) {
    event.preventDefault();
    if (!sitePersonName || !sitePersonNumber || !name || !number || !email) {
      toast.success("Please fill in all required fields.", {
        type: "warning",
        theme: "colored",
      });
      return;
    }
    if (
      !new RegExp("^[0-9]{10}$").test(number) ||
      !new RegExp("^[0-9]{10}$").test(sitePersonNumber)
    ) {
      toast.success("Phone Number must be 10 digits.", {
        type: "warning",
        theme: "colored",
      });
      return;
    }
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailPattern.test(email)) {
      toast.success("Please enter a valid email address.", {
        type: "warning",
        theme: "colored",
      });
      return;
    }

    try {
      fetch(`https://parksmart.in/website/referParkingSite`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: referralId,
          site_name: siteName,
          site_address: siteAddress,
          site_pincode: pincode,
          site_city: city,
          site_state: state,
          spoc_name: sitePersonName,
          spoc_mobile_number: sitePersonNumber,
          spoc_designation: sitePersonDesignation,
          referrer_name: name,
          referrer_mobile_number: number,
          referrer_email: email,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            notify("Network response was not ok");
          }
          return response.json();
        })
        .then((json) => {
          console.log(json);
          // Proceed to the next page if validations pass
          if (!json.success) {
            toast.success(json.message, {
              type: "warning",
              theme: "colored",
            });
            return;
          }
          toast.success("Thank you for referring", {
            type: "success",
            theme: "colored",
          });
          resetHandler();
        })
        .catch((error) => {
          notify("Something went wrong", "warning");
        });
    } catch (error) {
      notify("An error occurred while processing your request", "error");
    }
  }

  useEffect(() => {
    handlePincodeChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pincode]);

  return (
    <div className="referral">

      <div className="referral-earn">
        <div className="referral-section">
          <div className="heading-section">
            <p className="earn-section-earn">Refer & Earn</p>
            <p className="money-section-earn">
              <span>&#x20b9;</span>5,000*
            </p>
          </div>
          <div className="gif-section-earn">
            <img className="gify-earn" src={Gify} alt="" />
          </div>
          <div>
            <p className="sub-section-earn">
              Refer your Society and IT Park
            </p>
          </div>
          <div className="summary-section-earn">
            Refer residential societies or IT parks to ParkSmart,
            share site details, and receive regular updates. On
            successful conversions, enjoy an exciting bonus of{" "}
            <span style={{ fontWeight: "bold" }}>₹5,000</span>. Join
            us in automate your gates for authorised vehicles, and
            reap the benefits with ParkSmart Referral Program. Refer
            us today!
          </div>
        </div>
      </div>

      <div className="referral-site">
        {page === 1 && (
          <>
            <label
              class="formbold-form-label formbold-form-label-2"
              style={{ color: "#334D6E" }}
            >
              Site Details
            </label>
            <div class="formbold-mb-5" style={{ marginTop: 10 }}>
              <label for="name" class="formbold-form-label">
                {" "}
                Site Name*{" "}
              </label>
              <input
                type="text"
                name="name"
                id="name"
                class="formbold-form-input"
                required
                value={siteName}
                onChange={(event) =>
                  setSiteName(event.target.value)
                }
              />
            </div>
            <div class="formbold-mb-5">
              <label for="address" class="formbold-form-label">
                {" "}
                Site Address*{" "}
              </label>
              <input
                type="text"
                name="address"
                id="address"
                class="formbold-form-input"
                required
                value={siteAddress}
                onChange={(event) =>
                  setSiteAddress(event.target.value)
                }
              />
            </div>
            <div class="formbold-mb-5">
              <label for="pincode" class="formbold-form-label">
                {" "}
                Pincode*{" "}
              </label>
              <input
                name="pincode"
                id="pincode"
                class="formbold-form-input"
                required
                maxLength={6}
                pattern="[0-9]*" // Use [0-9]* pattern to allow only numbers
                value={pincode}
                onChange={(event) => {
                  const input = event.target.value;
                  const numbersOnly = input.replace(/\D/g, ""); // Remove non-numeric characters
                  setPincode(numbersOnly);
                }}
              />
            </div>
            <div class="flex flex-wrap formbold--mx-3">
              <div class="w-full sm:w-half formbold-px-3">
                <div class="formbold-mb-5 w-full">
                  <label for="city" class="formbold-form-label">
                    {" "}
                    City{" "}
                  </label>
                  <input
                    type="text"
                    name="city"
                    id="city"
                    label="Pin Code"
                    class="formbold-form-input"
                    disabled
                    value={city}
                  />
                </div>
              </div>
              <div class="w-full sm:w-half formbold-px-3">
                <div class="formbold-mb-5">
                  <label
                    for="state"
                    class="formbold-form-label"
                  >
                    {" "}
                    State{" "}
                  </label>
                  <input
                    type="text"
                    name="state"
                    id="state"
                    class="formbold-form-input"
                    disabled
                    value={state}
                  />
                </div>
              </div>
            </div>

            <div className="button-section-site">
              <button
                className="button-site"
                type="submit"
                onClick={handlePageToTwo}
              >
                Next
              </button>

            </div>
          </>
        )}
        {page === 2 && (
          <>
            <div
              class="formbold-form-label formbold-form-label-2"
              style={{ color: "#334D6E", display: 'flex', justifyItems: "flex-start", alignItems: "center" }}
            >
              <button onClick={handlePageToOne} style={{ border: "none", background: "none", cursor: "pointer", color: "#334D6E", marginRight: 10 }}>
                <ArrowBackIcon style={{ fontSize: 25 }} />
              </button>
              <div style={{ marginTop: -10 }}>Site Contact Person</div>
            </div>

            <div class="flex flex-wrap formbold--mx-3">
              <div class="w-full sm:w-half formbold-px-3">
                <div class="formbold-mb-5 w-full">
                  <label for="city" class="formbold-form-label">
                    {" "}
                    Contact Person Name*{" "}
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    class="formbold-form-input"
                    required
                    value={sitePersonName}
                    onChange={(event) =>
                      setSitePersonName(event.target.value)
                    }
                  />
                </div>
              </div>
              <div class="w-full sm:w-half formbold-px-3">
                <div class="formbold-mb-5">
                  <label
                    for="state"
                    class="formbold-form-label"
                  >
                    {" "}
                    Contact Person Number*{" "}
                  </label>
                  <input
                    name="pincode"
                    id="pincode"
                    class="formbold-form-input"
                    required
                    maxLength={10}
                    pattern="[0-9]*" // Use [0-9]* pattern to allow only numbers
                    value={sitePersonNumber}
                    onChange={(event) => {
                      const input = event.target.value;
                      const numbersOnly = input.replace(/\D/g, ""); // Remove non-numeric characters
                      setSitePersonNumber(numbersOnly);
                    }}
                  />
                </div>
              </div>
            </div>
            <div class="formbold-mb-5">
              <label for="email" class="formbold-form-label">
                {" "}
                Contact Person Designation{" "}
              </label>
              <input
                name="pincode"
                id="pincode"
                class="formbold-form-input"
                value={sitePersonDesignation}
                onChange={(event) => {
                  const input = event.target.value;
                  setSitePersonDesignation(input);
                }}
              />
            </div>
            <label
              class="formbold-form-label formbold-form-label-2"
              style={{ color: "#334D6E", marginBottom: 5 }}
            >
              Your Details
            </label>
            <div class="formbold-mb-5">
              <label for="email" class="formbold-form-label">
                {" "}
                Your Name*{" "}
              </label>
              <input
                type="text"
                name="name"
                id="yourname"
                class="formbold-form-input"
                required
                value={name}
                onChange={(event) =>
                  setName(event.target.value)
                }
              />
            </div>
            <div class="formbold-mb-5">
              <label for="email" class="formbold-form-label">
                {" "}
                Your Number*{" "}
              </label>
              <input
                name="pincode"
                id="pincode"
                class="formbold-form-input"
                required
                maxLength={10}
                pattern="[0-9]*" // Use [0-9]* pattern to allow only numbers
                value={number}
                onChange={(event) => {
                  const input = event.target.value;
                  const numbersOnly = input.replace(/\D/g, ""); // Remove non-numeric characters
                  setNumber(numbersOnly);
                }}
              />
            </div>
            <div class="formbold-mb-5">
              <label for="email" class="formbold-form-label">
                {" "}
                Your Email ID*{" "}
              </label>
              <input
                type="email"
                name="emailId"
                id="youremailId"
                class="formbold-form-input"
                required
                value={email}
                onChange={(event) =>
                  setEmail(event.target.value)
                }
              />
            </div>

            <div className="button-section-site">
              <button className="button-site" onClick={handleApply}>
                Apply
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const ReferralDialog = () => {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const location = useLocation();
  const isSmallDevice = useMediaQuery((theme) =>
    customTheme.breakpoints.down("sm")
  );

  const redirectToReferralSection = () => {
    navigate("/sales_enquiry")
  }

  const closeDialog = () => {
    setOpenDialog(false)
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const aidParam = searchParams.get("aid");
    // Check if the "aid" parameter is present and set the startFromPage1 state accordingly
    if (aidParam === "referral") navigate("/sales_enquiry")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpenDialog(true);
    }, 5000);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
        <Dialog open={openDialog} onClose={closeDialog}
          PaperProps={{ style: { borderRadius: isSmallDevice ? "" : "15px", width: isSmallDevice ? "100vw" : "", height: isSmallDevice ? "100vh" : "", margin: "0" } }}
        >
          <div>
            <div className="referral-earn" style={{ padding: 20 }}>
              <div className="referral-section">
                <div className="heading-section">
                  <div style={{ flex: 1 }}>
                    <p className="earn-section-earn">Refer & Earn</p>
                    <p className="money-section-earn">
                      <span>&#x20b9;</span>5,000*
                    </p>
                  </div>
                  <button style={{ background: "none", border: "none", marginLeft: 60 }} onClick={closeDialog}><CloseIcon style={{ fontSize: "30", color: "#334d6e" }} /></button>
                </div>
                <div className="gif-section-earn">
                  <img className="gify-earn" src={Gify} alt="" />
                </div>
                <div>
                  <p className="sub-section-earn">
                    Refer your Society and IT Park
                  </p>
                </div>
                <div className="summary-section-earn">
                  Refer residential societies or IT parks to ParkSmart,
                  share site details, and receive regular updates. On
                  successful conversions, enjoy an exciting bonus of{" "}
                  <span style={{ fontWeight: "bold" }}>₹5,000</span>. Join
                  us in automate your gates for authorised vehicles, and
                  reap the benefits with ParkSmart Referral Program. Refer
                  us today!
                </div>
                <div className="button-section-earn">
                  <button className="button-earn" onClick={redirectToReferralSection}>
                    Refer Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
  );
};

const ReferralSection = () => {
  const navigate = useNavigate();

  const redirectToReferralSection = () => {
    navigate("/sales_enquiry")
  }

  return (
    <div className="referral-content">
        <div className="referral-section">
          <div className="heading-section">
            <p className="earn-section">Earn</p>
            <p className="money-section">
              <span>&#x20b9;</span>5,000
            </p>
          </div>
          <div>
            <p className="sub-section">Refer your Society and IT Park</p>
          </div>
          <div className="summary-section">
            Refer residential societies or IT parks to ParkSmart, share site
            details, and receive regular updates. On successful conversions, enjoy
            an exciting bonus of{" "}
            <span style={{ fontWeight: "bold" }}>₹5,000</span>. Join us in
            automate your gates for authorised vehicles, and reap the benefits
            with ParkSmart Referral Program. Refer us today!
          </div>
          <div className="button-section">
            <button className="button" onClick={() => redirectToReferralSection()}>
              Refer Now
            </button>
          </div>
        </div>
        <div className="gif-section">
          <img className="gify" src={Gify} alt="" />
        </div>
    </div>
  );
};

export { Referral, ReferralDialog, ReferralSection };
