import React, { useState } from 'react'
import './UnsubscribeScreen.scss'
import unsubscribe_newsletterImg from '../../images/newsletter_img.webp'
import loader from '../../images/loader.svg'
import notify from '../../components/CustomUseNotify'
import config from '../../config.json'
import { useSearchParams, useNavigate } from "react-router-dom";

const data = ["Your emails are not relevant to me", "I no longer want to receive these emails", "The emails are spam and should be reported", "Other"];

const UnsubscribeScreen = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const userEmail = searchParams.get('email');
  const [loading, setLoading] = useState(false)

  const [isOpen, setOpen] = useState(false);
  const [items] = useState(data);
  const [selectedItem, setSelectedItem] = useState(null);

  const toggleDropdown = () => setOpen(!isOpen);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setOpen(false)
  }

  const unsubscribeHandler = () => {
    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(userEmail)) {
      notify('Invalid email id.', 'warning')
      return;
    }

    if (selectedItem == null || selectedItem === '') {
      notify('Please select your reason.', 'warning')
      return;
    }

    setLoading(true)
    fetch(`${config.HOST_NAME}/website/unsubscribe`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: userEmail, reason: selectedItem }),
    })
      .then(response => response.json())
      .then(json => {
        setLoading(false)
        if (json.success) {
          notify(json.message, 'success')
          navigate('/')
        }
        else notify(json.message, 'warning')
      })
      .catch(error => {
        notify('Something went wrong', 'warning')
        setLoading(false)

      });
  }
  return (
    <div className='unsubscribe_newsletter'>
      <div className='unsubscribe_newsletter_container'>
        <div className='unsubscribe_newsletterImg'>
          <img src={unsubscribe_newsletterImg} alt='unsubscribe_newsletter' />
        </div>

        <div className='unsubscribe_newsletter_subscriber_container'>
          <p className='p1' ><span style={{ color: "#18cc69" }}>{userEmail}</span> Unsubscribe from our mailing list</p>
          <p className='p2' >To help us improve our services, we would be grateful if you could tell us why</p>

          {/* <div className='subscribe_now'>
            <EmailIcon style={{ fontSize: 40, color: "#6E6E6E", marginLeft: 15 }} />
            <input className='customUserEmailInput' style={{ backgroundColor: "#fff" }}
              type="text"
              value={userEmail}
              placeholder='example@gmail.com'
              disabled
            />
          </div> */}

          <div className='dropdown' style={{ marginTop: 20 }}>
            <div className='dropdown-header' onClick={toggleDropdown}>
              {selectedItem ? selectedItem : "Please select your reason"}
              <i className={`fa fa-chevron-right icon ${isOpen && "open"}`}></i>
            </div>
            <div className={`dropdown-body ${isOpen && 'open'}`}>
              {items.map((item, index) => (
                <div className="dropdown-item" onClick={e => handleItemClick(item)} id={index}>
                  <span className={`dropdown-item-dot ${item === selectedItem && 'selected'}`}>• </span>
                  {item}
                </div>
              ))}
            </div>
          </div>

          <div className='subscribe_now_btn' onClick={unsubscribeHandler} style={{ marginTop: 20 }}>
            <p>UNSUBSCRIBE NOW</p>
            {loading && <img src={loader} alt='loader' style={{ width: '20px', marginLeft: '5px' }} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnsubscribeScreen