import React, { useState, useEffect } from 'react'
import './OurProducts.scss'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';
import iphone_frame from '../../images/iphone_frame.webp';
import gate_access_img from '../../images/gate_access_img.webp';
import fastag_img from '../../images/fastag_img.webp';
import monthly_pass_img from '../../images/monthly_pass_img.webp';
import parking_img from '../../images/parking_img.webp';

import { LazyLoadImage } from "react-lazy-load-image-component";

const tabs = [
    { index: 0, name: 'Gate Access', points: ["Now, you don't need to wait in long queues outside your own society and office. ParkSmart gate management system provides a seamless, contactless access to all your vehicles. "] },
    { index: 1, name: 'Parking Visibility', points: ["Heading out? Know the availability, pricing of any parking lot near your destination."] },
    { index: 2, name: 'Fastag', points: ["You never have to wait at tolls or parkings with the Parksmart FASTag. Get yours now or recharge your existing one through the ParkSmart app."] },
    { index: 3, name: 'Monthly Pass', points: ["Just tap in and tap out of any parking lot with this convenient, safe and contactless monthly parking pass."] },
]

const OurProducts = () => {
    const [selectedTab, setSelectedTab] = useState(tabs[1])
    const [autoSlideEnabled, setAutoSlideEnabled] = useState(true);

    const arrowHandler = (type) => {
        const selectedIndex = selectedTab.index
        if (type === 1) {
            if (selectedIndex < tabs.length - 1) {
                setSelectedTab(tabs[selectedIndex + 1]);
            } else {
                setSelectedTab(tabs[0]);
            }
        }
        else if (type === 0) {
            if (selectedIndex !== 0) {
                setSelectedTab(tabs[selectedIndex - 1]);
            } else {
                setSelectedTab(tabs[tabs.length - 1]);
            }
        }
    }

    useEffect(() => {
        let interval = null;
    
        if (autoSlideEnabled) {
          interval = setInterval(() => {
            arrowHandler(1)
          }, 4000);
        }
    
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [autoSlideEnabled, selectedTab]);

    return (
        <div className='our_products_section'>
            <div className='our_products_container'>
                <p>Features & Offerings</p>
            </div>

            <div className='products_tabs'>
                {tabs.map((tab) => (
                    <div key={tab.index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: selectedTab.name === tab.name ? "#2C3041" : "#F5F8FA", color: selectedTab.name === tab.name ? "#F5F8FA" : "#2C3041", borderRadius: '10px', height: '60px', cursor: 'pointer', padding: '0px 4px' }} onClick={() => { 
                        setSelectedTab(tab) 
                        setAutoSlideEnabled(false)
                    }}>
                        <p>{tab.name}</p>
                    </div>
                ))}
            </div>

            <div className='products_tabs_item_container'>
                <div className='product_images_container' style={{ width: 225, height: 480 }}>
                    <LazyLoadImage className="productImg screen" src={gate_access_img} alt="Gate Access" style={{ zIndex: 3 - Math.abs(selectedTab.index - 0), transform: selectedTab.index === 3 ? "translateX(110px) scale(0.88)" : `translateX(${((0 - selectedTab.index) % 2) * 110}px) scale(${1 - Math.abs(selectedTab.index - 0) * 0.12})` }} />
                    <LazyLoadImage className="productImg" src={iphone_frame} alt="IPhone Frame" style={{ zIndex: 3 - Math.abs(selectedTab.index - 0), transform: selectedTab.index === 3 ? "translateX(110px) scale(0.88)" : `translateX(${((0 - selectedTab.index) % 2) * 110}px) scale(${1 - Math.abs(selectedTab.index - 0) * 0.12})` }} />
                    <LazyLoadImage className="productImg screen" src={parking_img} alt="Parking Visibility" style={{ zIndex: 3 - Math.abs(selectedTab.index - 1), transform: `translateX(${((1 - selectedTab.index) % 2) * 110}px) scale(${1 - Math.abs(selectedTab.index - 1) * 0.12})` }} />
                    <LazyLoadImage className="productImg" src={iphone_frame} alt="IPhone Frame" style={{ zIndex: 3 - Math.abs(selectedTab.index - 1), transform: `translateX(${((1 - selectedTab.index) % 2) * 110}px) scale(${1 - Math.abs(selectedTab.index - 1) * 0.12})` }} />
                    <LazyLoadImage className="productImg screen" src={fastag_img} alt="FASTag" style={{ zIndex: 3 - Math.abs(selectedTab.index - 2), transform: `translateX(${((2 - selectedTab.index) % 2) * 110}px) scale(${1 - Math.abs(selectedTab.index - 2) * 0.12})` }} />
                    <LazyLoadImage className="productImg" src={iphone_frame} alt="IPhone Frame" style={{ zIndex: 3 - Math.abs(selectedTab.index - 2), transform: `translateX(${((2 - selectedTab.index) % 2) * 110}px) scale(${1 - Math.abs(selectedTab.index - 2) * 0.12})` }} />
                    <LazyLoadImage className="productImg screen" src={monthly_pass_img} alt="Monthly Pass" style={{ zIndex: 3 - Math.abs(selectedTab.index - 3), transform: selectedTab.index === 0 ? "translateX(-110px) scale(0.88)" : `translateX(${((3 - selectedTab.index) % 2) * 110}px) scale(${1 - Math.abs(selectedTab.index - 3) * 0.12})` }} />
                    <LazyLoadImage className="productImg" src={iphone_frame} alt="IPhone Frame" style={{ zIndex: 3 - Math.abs(selectedTab.index - 3), transform: selectedTab.index === 0 ? "translateX(-110px) scale(0.88)" : `translateX(${((3 - selectedTab.index) % 2) * 110}px) scale(${1 - Math.abs(selectedTab.index - 3) * 0.12})` }} />
                  </div>

                <div className='arrow_description_container'>
                    <IconButton onClick={() => {
                        arrowHandler(0)
                        setAutoSlideEnabled(false)
                    }} size="large" aria-label="arrow">
                        <ArrowBackIosIcon className='mui_icon_style' style={{ position: "relative", left: 6 }} />
                    </IconButton>
                    <div>
                        <p style={{ color: '#272840', fontWeight: 700 }}>{selectedTab.name}</p>
                        <div className='products_description_container'>
                            {selectedTab.points.map((point, index) => (
                                <p key={index} style={{ fontWeight: 400, marginTop: 20, color: "#4F4F4F" }}>{point}</p>
                            ))}
                        </div>
                    </div>
                    <IconButton onClick={() => {
                        arrowHandler(1)
                        setAutoSlideEnabled(false)
                    }} size="large" aria-label="arrow">
                        <ArrowForwardIosIcon className='mui_icon_style' />
                    </IconButton>
                </div>
            </div>

            {/* <div className='products_tabs_item_container'>
                 <img src={selectedTab.image} alt='frame 2' /> 

                 <div style={{ position: 'relative', width: '360px' }}>
                    <div className='iphone_frame_'>
                        <img src={iphone_frame} alt='Iphone Frame' />
                        <img src={fastag_img} alt='App gif' />
                    </div>
                    <img className='test' src={fastag_img} alt='App gif' />
                </div>

                <div className='arrow_description_container'>
                    <IconButton onClick={() => arrowHandler(0)} size="large">
                        <ArrowBackIosIcon style={{ fontSize: 40, position: "relative", left: 8 }} />
                    </IconButton>
                    <div>
                        <p style={{ fontSize: 40, color: '#272840', fontWeight: 700 }}>{selectedTab.name}</p>
                        <div className='products_description_container'>
                            {selectedTab.points.map((point) => (
                                <p style={{ fontSize: 18, fontWeight: 400, marginTop: 20, color: "#4F4F4F" }}>{point}</p>
                            ))}
                        </div>
                    </div>
                    <IconButton onClick={() => arrowHandler(1)} size="large">
                        <ArrowForwardIosIcon style={{ fontSize: 40 }} />
                    </IconButton>
                </div>
            </div> */}
        </div>
    )
}

export default OurProducts