import React, { useState, useEffect } from "react";
import { Fab } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import WhatsAppImage from "../images/whatsapp.svg";
import CloseIcon from '@mui/icons-material/Close';
import "./fab.scss";
import { motion, useViewportScroll, useTransform } from "framer-motion"
import { LazyLoadImage } from "react-lazy-load-image-component";

const whatsappContacts = [
	{
		name: "Rahul",
		description: "Contact for tech and business related queries",
		mobile: "919999095885",
	},
	{
		name: "Ratan",
		description: "Contact for product or marketing related concerns",
		mobile: "919971742678",
	},
];

export default function Whatsapp(props) {
	const [dialogVisible, setDialogVisible] = useState(false);
	const { scrollY } = useViewportScroll()
	const WhatsAppOpacity = useTransform(scrollY, [950, 1200], [0, 1])

	const toggleDialog = () => {
		setDialogVisible(!dialogVisible);
	};

	const updateVisibility = (event) => {
		let whatsappIframe = document.getElementById("kiwi-big-iframe-wrapper");
		if(whatsappIframe) {
			if(window.pageYOffset > 1200) {
				whatsappIframe.style.opacity = 1;
			} else {
				whatsappIframe.style.opacity = 0;
			}
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", updateVisibility);

		return () => {
			window.removeEventListener('scroll', updateVisibility);
		};
	}, []);

	return (
		<motion.div id="whatsappFabRoot" className="fab-root" style={{ opacity: WhatsAppOpacity }}>
			<div className="fab-container" onClick={toggleDialog}>
				<Fab className="fab-button fab-whatsappButton" aria-label="icon">
					{dialogVisible ?
						<CloseIcon style={{ fontSize: 27 }} />
						:
						<WhatsAppIcon style={{ fontSize: 36 }} />
					}
				</Fab>
			</div>

			<div className="fabPopover" style={dialogVisible ? { transform: `translate(0, 0)`, opacity: 1 } : { transform: `translate(0, 40px)`, opacity: 0, pointerEvents: "none" }}>
				<div className="header">
					<WhatsAppIcon />
					<div>
						<div className="title">Start a Conversation</div>
						<div className="paragraph3">Hi! Click one of our member below to chat on <b>Whatsapp</b></div>
					</div>
				</div>
				<div className="content">
					<div className="helperText">The team typically replies in a few minutes.</div>
					{whatsappContacts.map((contact, index) => (
						<a className="whatsappContact" key={index} href={`https://wa.me/${contact.mobile}`} target="_blank" rel="noopener noreferrer">
							<LazyLoadImage src={WhatsAppImage} alt="Whatsapp Icon" />
							<div>
								<div className="name">{contact.name}</div>
								<div className="description">{contact.description}</div>
							</div>
							<WhatsAppIcon />
						</a>
					))}
				</div>
			</div>
		</motion.div>
	);
}