import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
const dividerColor = "#E0E5EC";
const TemplateContext = React.createContext(null)

export const TemplateProvider = ({ children }) => {
    const theme = createTheme({
        palette: {
            primary: {
                main: '#18cc69',
                contrastText: '#fff',
            },
            secondary: {
                main: '#334D6E',
                contrastText: '#fff',
            },
            background: {
                default: "#fff",
                light: "#F5F8FA",
            },
            error: {
                main: "#de575e"
            },
            neutral: {
                main: "#c2cfe0"
            },
            divider: dividerColor,
        },
        typography: {
            fontFamily: `"Poppins-Medium","Arial","sans-serif"`,
            fontWeightLight: 400,
            fontWeightRegular: 500,
            fontWeightMedium: 600,
            fontWeightBold: 700,
        },
        shape: {
            roundedBorderRadius: 50,
        },
        overrides: {
            MuiTable: {
                root: {
                    borderCollapse: "unset",
                }
            },
            MuiTableCell: {
                root: {
                    borderBottomColor: dividerColor,
                    padding: "16px !important",
                },
                head: {
                    fontSize: 14,
                    fontWeight: 500,
                    color: "#555",
                    borderBottomColor: dividerColor,
                },
                body: {
                    fontSize: 13,
                    fontWeight: 400,
                    color: "#707683",
                },
            },
            MuiTableSortLabel: {
                active: {
                    color: "#555 !important"
                }
            }
        },
    })

    return (
        <TemplateContext.Provider value="light">
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </TemplateContext.Provider>
    )
}

export default TemplateProvider