import React, { useState } from 'react'
import './Newsletter.scss'
import newsletterImg from '../../images/newsletter_img.webp'
import loader from '../../images/loader.svg'
import notify from '../../components/CustomUseNotify'
import config from '../../config.json'
import EmailIcon from '@mui/icons-material/Email';
import useWindowDimensions from '../../components/GetWindowDimensions';

import { LazyLoadImage } from "react-lazy-load-image-component";

const Newsletter = () => {
    const { width } = useWindowDimensions();

    const [userEmail, setUserEmail] = useState("")
    const [loading, setLoading] = useState(false)

    const newsletterHandler = () => {
        const emailRegex = /\S+@\S+\.\S+/;
        if (!emailRegex.test(userEmail)) {
            notify('Invalid email id.', 'warning')
            return;
        }

        setLoading(true)
        fetch(`${config.HOST_NAME}/website/subscribe`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: userEmail }),
        })
            .then(response => response.json())
            .then(json => {
                setLoading(false)
                setUserEmail('')
                if (json.success) notify(json.message, 'success')
                else notify(json.message, 'warning')
            })
            .catch(error => {
                notify('Something went wrong', 'warning')
                setLoading(false)

            });
    }
    return (
        <div className='newsletter'>
            <div className='newsletter_container'>
                <div className='newsletterImg'>
                    <LazyLoadImage src={newsletterImg} alt='newsletter' />
                </div>

                <div className='newsletter_subscriber_container'>
                    <p className='p1' >Subscribe to our newsletter{width > 450 ? " 🔥" : ""}</p>
                    <p className='p2' >All the latest news about cars on your tips</p>

                    <div className='subscribe_now'>
                        <EmailIcon className='email_icon_style' />
                        <input className='customUserEmailInput'
                            type="text"
                            value={userEmail}
                            onChange={(e) => setUserEmail(e.target.value)}
                            placeholder='example@gmail.com'
                        />


                        <div className='subscribe_now_btn' onClick={newsletterHandler}>
                            <p>SUBSCRIBE NOW</p>
                            {loading && <LazyLoadImage src={loader} alt='loader' style={{ width: '20px', marginLeft: '5px' }} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Newsletter