import React from 'react';
import '../screens/HomeScreen/HomeScreen.scss';
import { motion, useViewportScroll, useTransform } from "framer-motion";
import { LinkedIn, Facebook, YouTube, Twitter, Instagram } from '@mui/icons-material';
// import linkedIn from '../images/linkedin.png'
// import facebook from '../images/facebook.png'
// import youtube from '../images/youtube.png'
// import twitter from '../images/twitter.png'
// import instagram from '../images/instagram.png'

const SocialLinks = () => {
    const { scrollY } = useViewportScroll()
    const socialLinksOpacity = useTransform(scrollY, [950, 1200], [0, 1])
    return (
        <motion.div style={{ opacity: socialLinksOpacity }}>
            {/* <a href='https://www.linkedin.com/company/parksmarthq/' target="_blank" rel="noreferrer">
                <img src={linkedIn} alt='LinkedIn' />
            </a>
            <a href='https://www.instagram.com/parksmarthq/' target="_blank" rel="noreferrer">
                <img src={instagram} alt='Instagram' />
            </a>
            <a href='https://www.facebook.com/ParkSmartHQ' target="_blank" rel="noreferrer">
                <img src={facebook} alt='Facebook' />
            </a>
            <a href='https://twitter.com/parksmarthq/' target="_blank" rel="noreferrer">
                <img src={twitter} alt='Twitter' />
            </a>
            <a href='https://www.youtube.com/@parksmarthq' target="_blank" rel="noreferrer">
                <img src={youtube} alt='Youtube' />
            </a> */}
            <ul className="socialLinks">
                <li>
                    <a href='https://www.linkedin.com/company/parksmarthq/' target="_blank" rel="noreferrer" aria-label="Linkedin">
                        <LinkedIn />
                    </a>
                </li>
                <li>
                    <a href='https://www.instagram.com/parksmarthq/' target="_blank" rel="noreferrer" aria-label="Instagram">
                        <Instagram />
                    </a>
                </li>
                <li>
                    <a href='https://www.facebook.com/ParkSmartHQ' target="_blank" rel="noreferrer" aria-label="Facebook">
                        <Facebook />
                    </a>
                </li>
                <li>
                    <a href='https://twitter.com/parksmarthq/' target="_blank" rel="noreferrer" aria-label="Twitter">
                        <Twitter />
                    </a>
                </li>
                <li>
                    <a href='https://www.youtube.com/@parksmarthq' target="_blank" rel="noreferrer" aria-label="Youtube">
                        <YouTube />
                    </a>
                </li>
            </ul>
        </motion.div>

    )
}

export default SocialLinks