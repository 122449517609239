import React from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom'

const RequiredAuth = () => {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const location = useLocation()

    return (
        userData && userData.token ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace></Navigate>
    )
};

export default RequiredAuth;
