import { animate } from "framer-motion";
import React, { useEffect, useRef } from "react";
import useWindowDimensions from './GetWindowDimensions'

function AnimatedNumberCounter({ from, to, label }) {
    const { width } = useWindowDimensions();
    const ref = useRef();
    useEffect(() => {
        const controls = animate(from, to, {
            duration: 2.1,
            onUpdate(value) {
                ref.current.textContent = value.toFixed(0);
            }
        });
        return () => controls.stop();
    }, [from, to]);

    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
        <p style={{ fontWeight: '600' }}><span ref={ref} style={{ fontSize: width > 450 ? '40px' : '30px', color: "#18cc69" }} /><span style={{ fontSize: width > 450 ? '40px' : '25px', color: "#18cc69" }}>+</span> </p>
        <p style={{ fontWeight: '600' }}><span style={{ fontSize: width > 450 ? '22px' : '18px', color: "#2C3041", marginLeft: 4 }}>{label}</span></p>
    </div>
}

export default AnimatedNumberCounter