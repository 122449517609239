export const SWW = 'Something went wrong'

export const handleServerResponse = async (response) => {
    if (response.status === 200) {
        return response.json();
    } else if (response.status >= 500) {
        return { success: false, message: "Internal Server Error" };
    } else {
        let message = await response.text();
        if (typeof message !== "string" || message === "") {
            message = response.statusText;
        }
        if (message.length > 50) {
            message = `${message.substring(0, 47)}...`;
        }
        return { success: false, message: message };
    }
};