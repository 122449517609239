import React from 'react'
import '../App.scss'
import { motion, useViewportScroll, useTransform } from "framer-motion"
import { HashLink } from 'react-router-hash-link';

const ScrollDownAnimation = () => {
    const { scrollY } = useViewportScroll()
    const scrollerOpacity = useTransform(scrollY, [0, 100], [1, 0])
    return (
        <motion.div className="scroll_down_container" style={{ opacity: scrollerOpacity }}>
            <HashLink smooth to='/#hero' style={{ textDecoration: "none" }}>
                <div className="chevron"></div>
                <div className="chevron"></div>
                <div className="chevron"></div>
                <span className="text">Scroll down</span>
            </HashLink>
        </motion.div>
    )
}

export default ScrollDownAnimation