import React, { createContext, useState } from 'react'

export const ContextProvider = createContext(null)

const GlobalStateProvider = ({ children }) => {
    const savedUserData = JSON.parse(localStorage.getItem('userData'))
    const [userData, setUserData] = useState(savedUserData && savedUserData.data ? savedUserData : null)

    return (
        <ContextProvider.Provider
            value={{
                userData,
                setUserData
            }}
        >
            {children}
        </ContextProvider.Provider>
    )
}

export default GlobalStateProvider
