import React from 'react'
import './OurClients.scss';
import clientsImg from '../../images/our_clients.webp'

// const parkSmartRecord = [
//     { label: 'Users', counts: "1,00,000" },
//     { label: 'Parkings', counts: '2000' },
//     { label: 'Clients', counts: '1000' },
//     { label: '5 Starts', counts: '40,000' }
// ]

const OurClients = () => {
    return (
        <div className='our_clients'>
            <div className='our_clients_header_title'>
                <p><span style={{ color: '#18cc69' }}>Our</span> <span style={{ color: "#2C3041" }}>Clients</span></p>
            </div>

            <div className="scrollingImageContainer" style={{ display: "flex", marginTop: '0px', width: "100%", minHeight: 540, height: "34vw", overflow: 'hidden', position: "relative" }}>
                <div className="scrolling-image" style={{ backgroundImage: `url(${clientsImg})` }} />
                <div className="scrolling-image" style={{ backgroundImage: `url(${clientsImg})` }} />
                <div className="scrolling-image" style={{ backgroundImage: `url(${clientsImg})` }} />
                {/* <img src={clientsImg} alt='clients' style={{ width: '100%' }} />
                <img src={clientsImg} alt='clients' style={{ width: '100%' }} />
                <img src={clientsImg} alt='clients' style={{ width: '100%' }} /> */}
            </div>

            {/* <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', padding: '50px 130px 50px 130px' }}>
                {
                    parkSmartRecord.map((record) => (
                        <p style={{ fontWeight: '600' }}><span style={{ fontSize: '34px', color: "#18cc69" }}>{record.counts}</span> <span style={{ fontSize: '24px', color: "#2C3041" }}>{record.label}</span></p>
                    ))
                }
            </div> */}
        </div>
    )
}

export default OurClients