import React from 'react'
import { motion, useViewportScroll, useTransform } from "framer-motion"
import { useNavigate } from "react-router-dom"
import { HashLink } from 'react-router-hash-link';
import '../screens/HomeScreen/HomeScreen.scss'
import logo from '../images/logo_black.png'
import { Button } from '@mui/material';
const NavBar = () => {

    const navigate = useNavigate();
    const { scrollY } = useViewportScroll()
    const navBarOpacity = useTransform(scrollY, [999, 1000], [0, 1])

    // eslint-disable-next-line no-unused-vars
    const loginHandler = () => {
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = localStorage.getItem('token')

        if (token && userData && userData.token) navigate('/buyFastag')
        else navigate('/login')
    }


    return (
        <motion.div style={{ height: 94, opacity: navBarOpacity, position: 'absolute' }}>
            <div className='hero_section_header'>
                <HashLink smooth to='/#hero' className='logo_container'>
                    <img src={logo} alt='logo'
                    />
                </HashLink>
                <div>
                    {/* <div className='buy_fastag_btn'
                        onClick={loginHandler}
                    >
                        <span className='new_container'>Buy FASTag</span>
                        <p>Login Now!</p>
    </div> */}
                    <div className="dashboard_link_container">
                        <a href='https://parksmart.co.in/business/#/login' target="_blank" rel="noreferrer" className='dashboard_link' >
                            <Button color="primary" variant="contained" style={{ textTransform: "none", fontSize: 16, letterSpacing: "0.06em", height: "40px", minWidth: "120px", backgroundColor: "#18CC69", color: "#FFF", fontFamily: 'Poppins', margin:"auto" }} disableElevation>
                            Business Login
                            </Button>
                        </a>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default NavBar