import React, { useRef, useEffect, useState } from 'react'
import '../../App.scss'
import HeroSection from '../../sections/Hero/Hero';
import OurProducts from '../../sections/OurProducts/OurProducts';
import Business from '../../sections/Business/Business';
import ContactUs from '../../sections/ContactUs/ContactUs';
import OurClients from '../../sections/OurClients/OurClients';
import Newsletter from '../../sections/Newsletter/Newsletter';
import Footer from '../../sections/Footer/Footer';
import Whatsapp from '../../components/Whatsapp';
import NavBar from '../../components/NavBar';
import SocialLinks from '../../components/SocialLinks';
import DashboardLogin from '../../sections/DashboardLogin/DashboardLogin';
// import Call from '../../components/Call';
import {ReferralSection, ReferralDialog} from "../../sections/Referral/Referral"
// import Facebook from '../../components/Facebook';
// import Team from '../../sections/Team/Team';

// const LazyFacebook = lazy(() => import('../../components/Facebook'))

const HomeScreen = () => {

    const contactUsRef = useRef(null);
    const contactUsFormRef = useRef(null);
    const [isContactRef, setIsContactRef] = useState(false)
  
    const scrollToSectionFromHash = () => {
      const hash = window.location.hash;
      if (hash === '#contact_us' && contactUsRef.current) {
        contactUsRef.current.scrollIntoView({ behavior: 'smooth' });
        setIsContactRef(true)
      } else if (hash === '#contact_us_form' && contactUsFormRef.current) {
        contactUsFormRef.current.scrollIntoView({ behavior: 'smooth' });
        setIsContactRef(true)
      }
    };
  
 
    useEffect(() => {
      scrollToSectionFromHash();
    }, []);

    return (
        <div>
            <NavBar />
            <HeroSection isContactRef={isContactRef} />
            <OurProducts />
            <Business />
            <ReferralSection />
            <ContactUs contactUsRef={contactUsRef} contactUsFormRef={contactUsFormRef} />
            <DashboardLogin/>
            <OurClients />
            <Newsletter />
            {/* <Team /> */}
            <Footer />
            <SocialLinks />
            <Whatsapp />
            {/*<Call/>*/}
            {/* <Suspense fallback={<div>Loading...</div>}>
                <LazyFacebook />
            </Suspense> */}
            {!isContactRef && <ReferralDialog/>}
        </div>
    );
}

export default HomeScreen;
