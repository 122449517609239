import React, { useState, useEffect, useContext } from 'react'
import './LoginScreen.scss'
import login_logo_img from '../../images/login_logo_img.png'
import { useNavigate, useLocation } from "react-router-dom"

import notify from '../../components/CustomUseNotify'
import { handleServerResponse, SWW } from "../../utils";
import { ENVIRONMENT, ENVIRONMENT_SMS_RETRIEVER_HASH } from "../../shareData";
import config from '../../config.json'
import loader from '../../images/loader.svg'
import { ContextProvider } from '../../stateProvider/StateProvider'
import CallIcon from '@mui/icons-material/Call';
import LockIcon from '@mui/icons-material/Lock';
import TiltImageAnimation from '../../components/TiltImageAnimation'

const NewLoginScreen = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { setUserData } = useContext(ContextProvider)

    const [mobileNumber, setMobileNumber] = useState("")
    const [messageId, setMessageId] = useState(null)
    const [sendOtpLoader, setSendOtpLoader] = useState(false)
    const [sendOtpStatus, setSendOtpStatus] = useState(false)

    const [verifyOtpInput, setVerifyOtpInput] = useState("")
    const [verifyOtpLoader, setVerifyOtpLoader] = useState(false)

    const [reSendOtpLoader, setReSendOtpLoader] = useState(false)
    const [counter, setCounter] = useState(0)

    const initSendOtpStates = () => {
        setMobileNumber('')
        setMessageId(null)
        sendOtpLoader(false)
        sendOtpStatus(false)
        setVerifyOtpInput("")
    }

    const sendOtp = () => {
        if (!new RegExp("^[0-9]{10}$").test(mobileNumber)) {
            notify('Invalid mobile number', 'warning')
            return;
        }

        setSendOtpLoader(true);
        fetch(`${config.HOST_NAME}/commuter/users/sendOtp`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                mobile: mobileNumber,
                sms_retriever_hash: ENVIRONMENT_SMS_RETRIEVER_HASH[ENVIRONMENT]
            })
        })
            .then(response => handleServerResponse(response))
            .then((json) => {
                setSendOtpLoader(false);
                if (json.success) {
                    setSendOtpStatus(true)
                    setMessageId(json.data.message_id)
                } else notify(json.message, 'warning')

            })
            .catch((error) => {
                setSendOtpLoader(false);
                notify(SWW, 'warning')
            });
    }

    const verifyOtp = () => {
        setVerifyOtpLoader(true)

        fetch(`${config.HOST_NAME}/commuter/users/verifyOtp`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                mobile: mobileNumber,
                otp: verifyOtpInput,
                message_id: messageId,
                main_user: true,
            })
        })
            .then(response => handleServerResponse(response))
            .then((json) => {
                setVerifyOtpLoader(false)
                if (json.success) {

                    localStorage.setItem('token', json.token)
                    localStorage.setItem('userData', JSON.stringify(json))

                    setUserData(json)

                    if (location.state?.from) {
                        navigate(location.state.from)
                    }
                    else if (!location.state) {
                        navigate('/buyFastag')
                    }



                } else if (json.expired) {
                    notify(json.message)
                    initSendOtpStates()

                } else {
                    notify(json.message)
                }
            })
            .catch((error) => {
                setVerifyOtpLoader(false)
                notify(SWW, 'warning')
            });
    };

    const resendOTP = () => {
        setReSendOtpLoader(true)
        fetch(`${config.HOST_NAME}/commuter/users/retryOtp`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                mobile: mobileNumber,
                message_id: messageId,
                sms_retriever_hash: ENVIRONMENT_SMS_RETRIEVER_HASH[ENVIRONMENT]
            })
        })
            .then(response => handleServerResponse(response))
            .then((json) => {
                setReSendOtpLoader(false)
                notify(json.message)
                if (json.success) {
                    startResendOtpTimer();
                }
            })
            .catch((error) => {
                notify(SWW, 'warning')
                setReSendOtpLoader(false)
            });
    }

    const startResendOtpTimer = () => {
        setCounter(30)
    }

    useEffect(() => {
        const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);


    return (
        <div className='login_container'>
            <div className='login_container_wrap'>
                <div className='login_form'>
                    <TiltImageAnimation>
                        <img src={login_logo_img} alt="" style={{ marginLeft: '20px', maxWidth: '100%' }} />
                    </TiltImageAnimation>
                    <span className='login_form_title'>
                        USER LOGIN
                    </span>

                    {
                        !sendOtpStatus ?
                            <>
                                <div className='login_input_container'>
                                    <CallIcon fontSize='xs' />
                                    <input className='login_input'
                                        type="text"
                                        value={mobileNumber}
                                        onChange={(e) => setMobileNumber(e.target.value)}
                                        placeholder='Mobile Number'
                                    />
                                </div>
                                <div className='login_btn' onClick={sendOtp}>
                                    <p>NEXT</p>
                                    {sendOtpLoader && <img src={loader} alt='loader' style={{ width: '20px', marginLeft: '5px' }} />}
                                </div>
                            </> :
                            <>
                                <div className='login_input_container'>
                                    <LockIcon fontSize='xs' />
                                    <input className='login_input'
                                        type="text"
                                        value={verifyOtpInput}
                                        onChange={(e) => setVerifyOtpInput(e.target.value)}
                                        placeholder='Otp'
                                    />
                                </div>

                                <div className='login_btn' onClick={verifyOtp}>
                                    <p>LOGIN</p>
                                    {verifyOtpLoader && <img src={loader} alt='loader' style={{ width: '20px', marginLeft: '5px' }} />}
                                </div>

                                <p style={{ fontSize: 12, color: "#9899A1", marginTop: '10px', textAlign: 'center' }}>Didn't get the code? {counter !== 0 ?
                                    <span style={{ color: "#5E8DD3" }}>Wait for {counter}</span> :
                                    <span style={{ color: "#5E8DD3", fontWeight: "600", cursor: 'pointer' }} onClick={resendOTP}>RESEND {reSendOtpLoader && <img src={loader} alt='loader' style={{ width: '20px', marginLeft: '5px' }} />}</span>}
                                </p>
                            </>
                    }
                </div>
            </div>
        </div>
    )
}

export default NewLoginScreen